<template>
  <div class="service__detail">
    <service-detail-hero />
    <service-detail />
    <ContactSection />
  </div>
</template>
<script>
import ServiceDetailHero from "@/components/service_detail/ServiceDetailHero.vue";
import ServiceDetail from "@/components/service_detail/ServiceDetail.vue";
import ContactSection from "@/components/home/contact/ContactSection.vue";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      requried: true,
    },
  },
  components: {
    ServiceDetailHero,
    ServiceDetail,
    ContactSection,
  },
  metaInfo: {
    title: "Services",
  },
};
</script>

<style>
</style>